:root {
  --blur-color: #e294ff;
  --foreground-color: #1d1d1d;
  --page-bg-color: #fbfbfb;
}

.dashboard {
  background-color: var(--page-bg-color);
  color: var(--foreground-color);
  margin: 0;
  padding: 40px;
  min-height: 100vh;
}

.dashboard h1 {
  margin: 0 0 20px 0;
  padding: 0;
  font-weight: bold;
  font-size: 4em;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 2rem;
  margin-bottom: 64px;
}

.card {
  display: flex;
  flex-direction: column;
  width: 240px;
  height: 240px;
  background-color: #ffffff08;
  box-shadow: inset 0 0 0 1px #ffffff00, 0 1px 4px #00000030;
  border-radius: 24px;
  transition: 200ms ease-in-out;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  backdrop-filter: blur(2px);

  &:hover {
    transform: scale(1.015);
    backdrop-filter: blur(3px);
    box-shadow: inset 0 0 0 1px #ffffff00, 0 4px 12px 2px #00000050;
  }
}

.card-thumbnail {
  flex: 0 0 75%;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: #ddd;
}

.card-thumbnail img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card-title {
  background-color: var(--page-bg-color);
  flex: 0 0 25%;
  font-weight: 600;
  font-size: 1.2em;
  padding: 10px;
}

a {
  text-decoration: none;
  color: var(--foreground-color);
}

a:hover {
  text-decoration: none;
  color: var(--foreground-color);
}
